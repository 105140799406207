* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.as_mask {
  width: 100%;
  height: 100%;
  background: #fff;
  mask: url("../img/nature-sprite.png");
  mask-size: 2300% 100%;
}

.as_mask_cont {
  position: relative;
  width: 92.263vw;
  height: 94.004vw;
  overflow: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (min-width: 517px) {
  .as_mask_cont {
    width: 477px;
    height: 486px;
  }
}

@media (min-width: 517px) and (max-height: 500px) {
  .as_mask_cont {
    margin: 20px 0;
    top: 0;
    transform: translate(-50%, 0%);
  }
}

.triggerAnimation {
  animation: ani 1s steps(22) forwards 2.8s;
}

@keyframes ani {
  100% {
    mask-position: 100% 0;
  }
}

.as_font_a {
  width: 25.145vw;
  position: absolute;
  top: 32.689vw;
  left: 4.449vw;
}

@media (min-width: 517px) {
  .as_font_a {
    width: 130px;
    top: 169px;
    left: 23px;
  }
}

.as_font_r {
  position: absolute;
  width: 8.704vw;
  top: 43.520vw;
  left: 27.079vw;
}

@media (min-width: 517px) {
  .as_font_r {
    width: 45px;
    top: 225px;
    left: 140px;
  }
}

.as_font_r2 {
  left: 35.590vw;
}

@media (min-width: 517px) {
  .as_font_r2 {
    left: 184px;
  }
}

.as_font_e {
  position: absolute;
  width: 9.671vw;
  top: 44.487vw;
  left: 44.487vw;
}

@media (min-width: 517px) {
  .as_font_e {
    width: 50px;
    top: 230px;
    left: 230px;
  }
}

.as_font_b {
  position: absolute;
  width: 14.313vw;
  top: 35.203vw;
  right: 27.273vw;
}

@media (min-width: 517px) {
  .as_font_b {
    width: 74px;
    top: 182px;
    right: 141px;
  }
}

.as_font_o {
  position: absolute;
  width: 11.219vw;
  top: 43.520vw;
  right: 18.182vw;
}

@media (min-width: 517px) {
  .as_font_o {
    width: 58px;
    top: 225px;
    right: 94px;
  }
}

.as_font_l {
  position: absolute;
  width: 7.544vw;
  top: 35.203vw;
  right: 11.799vw;
}

@media (min-width: 517px) {
  .as_font_l {
    width: 39px;
    top: 182px;
    right: 61px;
  }
}

.as_line_top {
  width: 65.571vw;
  position: absolute;
  top: 3.544vw;
  left: 50%;
  transform: translateX(-50%);
}

@media (min-width: 517px) {
  .as_line_top {
    width: 339px;
    top: 39px;
  }
}

.as_line_bottom {
  width: 65.571vw;
  position: absolute;
  bottom: 3.544vw;
  left: 50%;
  transform: translateX(-50%);
}

@media (min-width: 517px) {
  .as_line_bottom {
    width: 339px;
    bottom: 39px;
  }
}

.as_leaf_top {
  width: 15.861vw;
  position: absolute;
  top: 17.408vw;
  left: 50%;
  transform: translateX(-50%);
}

@media (min-width: 517px) {
  .as_leaf_top {
    width: 82px;
    top: 90px;
  }
}

.as_leaf_bottom {
  top: initial !important;
  bottom: 17.408vw;
  transform: translateX(-50%) rotate(180deg);
}

@media (min-width: 517px) {
  .as_leaf_bottom {
    bottom: 90px;
  }
}

.as_text_bottom {
  position: absolute;
  width: 52.031vw;
  display: block;
  height: auto;
  left: 50%;
  bottom: 12vw;
  transform: translateX(-50%);
  opacity: 0;
  animation: fadeIn 1.5s ease-in 4s forwards;
}

@media (min-width: 360px) {
  .as_text_bottom {
    bottom: 10.251vw;
  }
}

@media (min-width: 517px) {
  .as_text_bottom {
    width: 269px;
    bottom: 53px;
  }
}

.as_icon {
  width: 12.379vw;
  height: 12.573vw;
  position: absolute;
  bottom: 0;
  opacity: 0;
  animation: fadeIn 1.5s ease-in 4s forwards;
}

@media (min-width: 517px) {
  .as_icon {
    width: 64px;
    height: 65px;
  }
}

.as_icon_fb {
  left: 0;
}

.as_icon_in {
  right: 0;
}

.st0 {
  clip-path: url(#SVGID_2_);
}

.st1 {
  fill: none;
  stroke: #000000;
  stroke-width: 10;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}

.st2 {
  fill: none;
  stroke: #000000;
  stroke-width: 3;
  stroke-miterlimit: 10;
}

.st3 {
  fill: none;
  stroke: #000000;
  stroke-width: 13;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}

.st4 {
  fill: none;
  stroke: #000000;
  stroke-width: 4;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}

.st5 {
  fill: none;
  stroke: #000000;
  stroke-width: 11;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}

.TCFsItjo_0 {
  stroke-dasharray: 844 846;
  stroke-dashoffset: 845;
  animation: TCFsItjo_draw 1933ms ease-in 0ms forwards;
}

.TCFsItjo_1 {
  stroke-dasharray: 109 111;
  stroke-dashoffset: 110;
  animation: TCFsItjo_draw 251ms ease-in 1933ms forwards;
}

.TCFsItjo_2 {
  stroke-dasharray: 84 86;
  stroke-dashoffset: 85;
  animation: TCFsItjo_draw 194ms ease-in 2185ms forwards;
}

.TCFsItjo_3 {
  stroke-dasharray: 138 140;
  stroke-dashoffset: 139;
  animation: TCFsItjo_draw 318ms ease-in 2379ms forwards;
}

.TCFsItjo_4 {
  stroke-dasharray: 93 95;
  stroke-dashoffset: 94;
  animation: TCFsItjo_draw 215ms ease-in 2697ms forwards;
}

.TCFsItjo_5 {
  stroke-dasharray: 37 39;
  stroke-dashoffset: 38;
  animation: TCFsItjo_draw 86ms ease-in 2913ms forwards;
}

@keyframes TCFsItjo_draw {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes TCFsItjo_fade {
  0% {
    stroke-opacity: 1;
  }
  89.74358974358975% {
    stroke-opacity: 1;
  }
  100% {
    stroke-opacity: 0;
  }
}

@keyframes fadeIn {
  89.74358974358975%, 100% {
    opacity: 1;
  }
}
